import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../../../Globals';

@Injectable({
    providedIn: 'root'
})
export class VacacionesAnterioresService {

    constructor(
        private http: HttpClient,
        private globals: Globals
    ) {}

    getComboFuncionarios(search: null, pagination: null) {
        const headers = this.globals.getHeader();
        const url = this.globals.makeUrl(`/comboPersonasFuncionariosRrhh`, search, pagination);
        return this.http.get(url, {headers});
    }

    getVacacionesCabeceras(idPersona: number) {
        const headers = this.globals.getHeader();
        const url = `${this.globals.API_ENDPOINT_ES}/listarVacacionesCabeceras?adm_persona_id=${idPersona}`;
        return this.http.get(url, {headers});
    }

    getVacacionesCabecerasAnteriores(idPersona: number) {
        const headers = this.globals.getHeader();
        const url = `${this.globals.API_ENDPOINT_ES}/preCargaVacacionesCabeceras?adm_persona_id=${idPersona}`;
        return this.http.get(url, {headers});
    }

    createVacacionCabeceraAnterior(jsonData: any) {
        const headers = this.globals.getHeader();
        const url = `${this.globals.API_ENDPOINT_ES}/vacacionesCabecerasAnteriores`;
        return this.http.post(url, jsonData, {headers});
    }

    updateVacacionCabeceraAnterior(idVacacion: number, jsonData: any) {
        const headers = this.globals.getHeader();
        const url = `${this.globals.API_ENDPOINT_ES}/vacacionesCabecerasAnteriores/${idVacacion}`;
        return this.http.put(url, jsonData, {headers});
    }
}
