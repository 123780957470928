import { DatosNoEncontradosModule } from './util/datos-no-encontrados/datos-no-encontrados.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { ArchitectUIState, rootReducer } from './ThemeOptions/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { AppRoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import {NgbActiveModal, NgbAlertModule, NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxLoadingModule } from 'ngx-loading';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { ClipboardModule } from 'ngx-clipboard';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ThemeOptions } from './theme-options';
import { DrawerComponent } from './Layout/Components/header/elements/drawer/drawer.component';
import { PageTitleComponent } from './Layout/Components/page-title/page-title.component';
import { LoginComponent } from './Pages/Login/login/login.component';
import { PerfilComponent } from './Pages/Usuario/perfil/perfil.component';
import { Globals } from './Globals';
import { LayoutModule } from './Layout/layout.module';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';
import { AuthTokenExpiredInterceptor } from './auth/auth-token-expired-interceptor';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { HomeComponent } from './Pages/home/home.component';
import { RecuperarPasswordComponent } from './Pages/Login/login/recuperar-password/recuperar-password.component';
import { RecuperarExitoComponent } from './Pages/Login/login/recuperar-password/recuperar-exito/recuperar-exito.component';
import { SpinnerModule } from './util/spinner/spinner.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BotonFiltroModule } from './util/boton-filtro/boton-filtro.module';
import { BotoneraEdicionModule } from './util/botonera-edicion/botonera-edicion.module';
import { BotoneraCreacionModule } from './util/botonera-creacion/botonera-creacion.module';
import { AdministracionComponent } from './Pages/Administracion/administracion.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { RegistrarUsuarioComponent } from './Pages/Login/login/registrar-usuario/registrar-usuario.component';
import { OlvideContrasenhaComponent } from './Pages/Login/login/olvide-contrasenha/olvide-contrasenha.component';
import { NumberFormatPipe } from './util/pipes/numberFormat.pipe';
import { SwalComponentModule } from './util/swal-component/swal-component.module';
// Import your library
import { NgxPermissionsModule } from 'ngx-permissions';
import { TarjetasDashboardModule } from './util/tarjetas-dashboard/tarjetas-dashboard.module';
import { ParametricosAdministracionComponent } from './Pages/Administracion/parametricos-administracion-tarjetas/parametricos-administracion.component';
import { TarjetasParametricosComponent } from './Pages/tarjetas-parametricos/tarjetas-parametricos.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { GrillaFuncionariosVacacionesComponent } from './util/busqueda-funcionarios-vacaciones/grilla-funcionarios-vacaciones/grilla-funcionarios-vacaciones.component';
import {MatCardModule} from "@angular/material/card";
import {MatInputModule} from "@angular/material/input";
import {MatTableModule} from "@angular/material/table";


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    LoginComponent,
    PerfilComponent,
    HomeComponent,
    RecuperarPasswordComponent,
    RecuperarExitoComponent,
    AdministracionComponent,
    RegistrarUsuarioComponent,
    OlvideContrasenhaComponent,
    NumberFormatPipe,
    ParametricosAdministracionComponent,
    TarjetasParametricosComponent,
    GrillaFuncionariosVacacionesComponent,
  ],
  entryComponents: [
    GrillaFuncionariosVacacionesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    LayoutModule,

    // Angular Bootstrap Components
    PerfectScrollbarModule,
    NgbModalModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    SweetAlert2Module.forRoot({}),
    ToastrModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    TextareaAutosizeModule,

    // Angular Material Components
    MatButtonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,

    //
    LottieModule.forRoot({ player: playerFactory }),
    SpinnerModule,
    FlexLayoutModule,
    BotonFiltroModule,
    BotoneraEdicionModule,
    DatosNoEncontradosModule,
    BotoneraCreacionModule,
    // Maps Module
    LeafletModule,
    SwalComponentModule,
    // Permisos
    NgxPermissionsModule.forRoot(),
    TarjetasDashboardModule,
    MatCardModule,
    MatInputModule,
    MatTableModule,
    NgbAlertModule
  ],
  providers: [
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,

    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenExpiredInterceptor,
      multi: true
    }, { provide: MAT_DATE_LOCALE, useValue: 'es-US' },
    ConfigActions,
    ThemeOptions,
    Globals,
    NgbActiveModal
  ],
  exports: [
    PageTitleComponent,
    PagesLayoutComponent,
    DrawerComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
