<div class="row mt-3">
  <div class="col-lg-12 col-xl-12">
    <div class="mb-3" [ngClass]="{'main-card card': !borderNone}">
      <div class="card-header-tab card-header">
        <div class="card-header-title">
          <i class="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
          Sin Elementos
        </div>
      </div>
      <div class="card-body text-center">
        <h6>
         ¡Ups! No se han encontrado registros.
        </h6>
        <h6 *ngIf="messageExtra">
            {{ messageExtra }}
        </h6>
        <ng-lottie *ngIf="!small" [options]="lottieConfig" [width]="200" [height]="200">
        </ng-lottie>
      </div>
    </div>
  </div>
</div>
