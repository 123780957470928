import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PermissionControllerService } from 'src/app/util/permission-controller/permission-controller.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ParametricosMenuBaseService } from '../Administracion/parametricos/services/parametricos-menu-base.service';

@Component({
  selector: 'app-tarjetas-parametricos',
  templateUrl: './tarjetas-parametricos.component.html',
  styleUrls: ['./tarjetas-parametricos.component.sass']
})
export class TarjetasParametricosComponent implements OnInit {

  heading = 'Paramétricos';
  cargando = false;
  tarjetas = [];
  parametricosCount;

  constructor(
    public parametricosMenu: ParametricosMenuBaseService,
    private errorService: ErrorService,
    private router: Router,
    public permissionController: PermissionControllerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getParametricos();
    this.permissionController.crearPermisosAdm();
    //this.addTarjetas();
  }

  getParametricos() {
    this.cargando = true;
    this.parametricosMenu.getCantidadPorItem()
      .subscribe((respuesta: any) => {
        this.parametricosCount = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.addTarjetas();
        this.cargando = false;
      }
      );
  }

  addTarjetas() {
    this.tarjetas = [
      {
        url: 'administracion/parametricos', titulo: 'Administración', subtitulo: 'Paramétricos de administración', contador: 8,
        permiso: 'RRHH/mant_personas_rrhh/list3000'
      },
      {
        url: 'admision-seguimiento/parametricos', titulo: 'Admisión', subtitulo: 'Paramétricos de admisión', contador: 13,
        permiso: 'ADMI/mant_paciente/list500'
      },
      {
        url: 'organizacion/parametricos', titulo: 'Organización', subtitulo: 'Paramétricos de organización', contador: 2,
        permiso: 'ORG/mant_organizacion/insert402'
      },
      {
        url: 'farmacia/parametricos', titulo: 'Farmacia', subtitulo: 'Paramétricos de farmacia', contador: 12,
        permiso: 'FAR/mant_entrada_cabecera/list700'
      },
      {
        url: 'servicios/parametricos', titulo: 'Servicio', subtitulo: 'Paramétricos de servicios', contador: 7,
        permiso: 'SER/mant_turno/list1000'
      },
      {
        url: 'laboratorio/parametricos', titulo: 'Laboratorio', subtitulo: 'Paramétricos de laboratorio', contador: 13,
        permiso: 'LAB/mant_analisis_cabecera/list1100'
      },
      {
        url: 'transporte/parametricos', titulo: 'Transporte', subtitulo: 'Paramétricos de transporte', contador: 8,
        permiso: 'TRANS/mant_agendamiento/list1300'
      },
      {
        url: 'ayuda-social/parametricos', titulo: 'Ayuda Social', subtitulo: 'Paramétricos de ayuda social', contador: 5,
        permiso: 'AYUSOC/mant_solicitud/list1540'
      },
      {
        url: 'ayuda-tecnica/parametricos', titulo: 'Ayuda Técnica', subtitulo: 'Paramétricos de ayuda técnica', contador: 7,
        permiso: 'AYUTEC/mant_entrada_ayuda_tecnica/list1700'
      },
      {
        url: 'tesoreria/parametricos', titulo: 'Tesoreria', subtitulo: 'Paramétricos de tesoreria', contador: 4,
        permiso: 'TESORE/mant_cheque/list1900'
      },
      {
        url: 'ventanilla/parametricos', titulo: 'Ventanilla', subtitulo: 'Paramétricos de ventanilla', contador: 4,
        permiso: 'VENTAN/mant_compromiso_transferencia/list1800'
      },
      {
        url: 'recursos-humanos/parametricos', titulo: 'Recursos Humanos', subtitulo: 'Paramétricos de RR.HH', contador: 25,
      },
      {
        url: 'contratos-suministros/parametricos', titulo: 'Contratos/Suministros', subtitulo: 'Paramétricos de contratos y suministros',
        contador: 11,
      },
    ];
  }

}
