<div class="h-100 bg-senadis-background bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center overflow-hidden">
    <div class="mx-auto app-login-box col-md-8">
      <div class="mx-auto mb-3">
        <img class="login-logo mx-auto d-block" src="./assets/images/logo_senadis.svg" alt="Logo de SenadisPro">
      </div>
      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <ng-template [ngIf]="iniciando">
            <form [formGroup]="loginForm">
              <div class="modal-body">
                <div class="h5 modal-title text-center">
                  <h4 class="mt-2">
                    <div class="font-weight-bold">Bienvenido</div>
                    <span>Por favor, inicie sesión con su cuenta</span>
                  </h4>
                </div>
                <div fxLayout="column" fxLayoutAlign="space-around">
                  <div class="form-group">
                    <input type="email" [placeholder]="loginPlaceHolder" class="form-control" formControlName="username"
                      id="email" required>
                    <small class="invalid-feedback"
                      *ngIf="loginForm.controls.username.hasError('email') && !loginForm.controls.username.hasError('required')">
                      Por favor, ingrese una dirección de email valida.
                    </small>
                    <!-- Quita las validaciones del bootstrap -->
                    <bfv-messages [ngStyle]="{'display':'none'}"></bfv-messages>
                    <small class="invalid-feedback"
                      *ngIf="loginForm.controls.username.hasError('required') && loginPor === 'email'">
                      El email es <strong>requerido</strong>
                    </small>
                    <small class="form-text text-muted"
                      *ngIf="loginForm.controls.username.hasError('required') && loginPor === 'doc'">
                      El número de documento es <strong>requerido</strong>
                    </small>
                  </div>
                  <div class="form-group">
                    <input type="password" placeholder="Contraseña" class="form-control" formControlName="password">
                    <small class="invalid-feedback" *ngIf="loginForm.controls.password.hasError('required')">
                      La contraseña es <strong>requerida</strong>
                      <!-- Quita las validaciones del bootstrap -->
                      <bfv-messages [ngStyle]="{'display':'none'}"></bfv-messages>
                    </small>
                  </div>
                </div>
                <div class="clearfix mt-1">
                  <button type="submit" class="btn-secondary btn-block" (click)="iniciarSesion(modal1)"
                    [disabled]="!loginForm.valid || cargando" style="cursor: pointer !important;">
                    <span class="spinner-border spinner-grow spinner-grow-sm text-primary" role="status"
                      aria-hidden="true" *ngIf="cargando"></span>
                    <span class="btn-texto text-uppercase font-weight-bold"> Ingresar </span>
                  </button>
                </div>
              </div>
            </form>
          </ng-template>
          <ng-template [ngIf]="registro">
            <app-registrar-usuario (hide)="cambiarInicioRegistro('inicio')"
              [minpassword]="parametrosIniciales.MINPASSWORD.MINPASSWORD"
              [maxpassword]="parametrosIniciales.MAXPASSWORD.MAXPASSWORD"></app-registrar-usuario>
          </ng-template>
          <ng-template [ngIf]="recuperar">
            <app-olvide-contrasenha (hide)="cambiarInicioRegistro('inicio')" [loginType]="loginPor">
            </app-olvide-contrasenha>
          </ng-template>
          <ng-template [ngIf]="cargandoServicioInicial">
            <div class="text-center">
              <app-spinner></app-spinner>
            </div>
          </ng-template>
        </div>
      </div>
      <div style="border-radius: 1rem;">
        <div class="col d-flex justify-content-center align-items-center">
          <img class="logo-gobierno" src="./assets/images/logo_gobierno.jpeg" alt="Logo del Gobierno">
        </div>
      </div>
      <div class="logo-enterprise">
        <div class="col d-flex justify-content-center align-items-center">
          <div class="text-white">
            Powered by <img src="./assets/images/logo-esolutions.svg" alt="Logo Esolutions">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modal1>
  <div class="card main-card">
    <div class="card-header">
      Cambiar Contraseña
      <button type="button" class="close text-black-50" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      </button>
    </div>
    <div class="card-body">
      <form #changePasword="ngForm" (ngSubmit)="changePassword()">
        <div class="modal-body">
          <div class="container">
            <div class="form-group">
              <label for="password">Ingrese Contraseña</label>
              <input type="password" class="form-control" id="password" required [(ngModel)]="newPassword.newPassword"
                name="password" />
            </div>
            <div class="form-group">
              <label for="confirmPassword">Confirme Contraseña</label>
              <input id="confirmPassword" class="form-control" type="password" [(ngModel)]="newPassword.confirmPassword"
                name="confirmPassword" required>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-success" [disabled]="!changePasword.form.valid">
            Aceptar
          </button>
        </div>
      </form>
    </div>
  </div>

</ng-template>