import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {VacacionesAnterioresService} from '../../../Pages/recursos-humanos/parametricos-recursos-humanos/vacaciones-anteriores/services/vacaciones-anteriores.service';
import {ErrorService} from '../../services/error.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-grilla-funcionarios-vacaciones',
  templateUrl: './grilla-funcionarios-vacaciones.component.html',
  styleUrls: ['./grilla-funcionarios-vacaciones.component.sass']
})
export class GrillaFuncionariosVacacionesComponent implements OnInit {

  cargando = false;
  searchForm: FormGroup;
  sinBusqueda = true;
  funcionarios: any[] = [];
  displayedColumns: string[] = ['datos', 'legajo', 'estado', 'acciones'];
  constructor(
      private vacacionesAnterioresService: VacacionesAnterioresService,
      private fB: FormBuilder,
      private errorService: ErrorService,
      private matDialogRef: MatDialogRef<GrillaFuncionariosVacacionesComponent>,
  ) { }

  ngOnInit(): void {
    this.makeForm();
  }

  makeForm() {
    this.searchForm = this.fB.group({
      nombre_apellido: null,
      nro_documento: null,
    })
  }

  getFuncionarios() {
    this.cargando = true;
    this.vacacionesAnterioresService.getComboFuncionarios(this.searchForm.value, null)
        .subscribe((response: any) => {
          this.funcionarios = response.data;
        }, error => {
          this.errorService.handleError(error);
        }, () => {
          this.cargando = false;
        });
  }

  closeBusqueda(){
    this.matDialogRef.close();
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  filtrar() {
    const nombre_apellido = this.searchForm.get('nombre_apellido').value;
    const nro_documento = this.searchForm.get('nro_documento').value;
    if (!nombre_apellido && !nro_documento){
      this.sinBusqueda = true;
      return;
    } else {
      this.sinBusqueda = false;
    }
    this.getFuncionarios();
  }

  onReset() {
    this.searchForm.reset();
    this.sinBusqueda = true;
  }

  funcionarioSeleccionado(object: any) {
    this.matDialogRef.close(object);
  }
}
