<div class="d-flex">
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">

      <div class="widget-content-wrapper">
        <div class="widget-content-left mr-3">
          <div class="widget-heading">
            <p class="mb-0 d-inline">Bienvenido,</p> <br> {{usuario.userData.nombre}} {{usuario.userData.apellido}}
          </div>
          <!-- <div class="widget-subheading">{{usuario.userName}}</div> -->
        </div>
        <div class="widget-content-left">
          <div class="d-inline-block" ngbDropdown>
            <button type="button" class="btn btn-link p-0 mr-2" ngbDropdownToggle id="dropdownForm1">
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" height="42" class="rounded-circle" src="./assets/images/avatars/avatar-man.png" alt="Foto de Perfil"
                    *ngIf="!imageToShow">
                  <img width="42" height="42" [src]="imageToShow" alt="Foto de Perfil" class="rounded-circle"
                    *ngIf="imageToShow && !cargando"  onerror="this.src='./assets/images/avatars/avatar-man.png'">
                  <div class="spinner-border text-primary" role="status" *ngIf="cargando">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownForm1" class="dropdown-menu-lg">
              <div class="dropdown-menu-header mb-0">
                <div class="dropdown-menu-header-inner bg-info">
                  <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <img width="42" height="42" class="rounded-circle" src="./assets/images/avatars/avatar-man.png" alt="Foto de Perfil"
                            *ngIf="!imageToShow">
                          <img width="42" height="42" [src]="imageToShow" alt="Foto de Perfil" class="rounded-circle" onerror="this.src='./assets/images/avatars/avatar-man.png'"
                            *ngIf="imageToShow">
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">{{usuario.userData.nombre}} {{usuario.userData.apellido}}</div>
                          <div class="widget-subheading opacity-8"></div>
                        </div>
                        <div class="widget-content-right mr-2">
                          <button class="btn-pill btn-shadow btn-shine btn btn-focus" (click)="logout()">Salir</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <perfect-scrollbar [autoPropagation]="true">
                <div class="list-group list-group-flush">
                  <button style="cursor: pointer;"
                  class="list-group-item list-group-item-action" (click)="verPerfil()">
                    Ver perfil
                  </button>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
