import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../theme-options';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LocalStorageService } from '../../../util/local-storage.service';
import { PermissionControllerService } from '../../../util/permission-controller/permission-controller.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;
  public config: PerfectScrollbarConfigInterface = {};

  constructor(public globals: ThemeOptions, private activatedRoute: ActivatedRoute,
    public permissionController: PermissionControllerService, private localStorage: LocalStorageService) {
  }

  @select('config') public config$: Observable<any>;
  @Input() hasPanelHeaderTitle: boolean;
  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboardsMenu';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    // this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });
    this.permissionController.crearPermisosAdm();
    this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }

  sinMenu() {
    alert('Menú en proceso');
    return;
  }
}
