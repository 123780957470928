<div class="h-100 bg-night-sky bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="app-logo-horizontal mx-auto mb-3"></div>
      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <div class="modal-body">
            <div class="h5 modal-title text-center">
              <h2 class="mt-2">
                <span>Contraseña cambiada con éxito</span></h2>
              <h5><i>Redirigiendo al login</i></h5>
            </div>
            <div fxLayout="row" fxLayoutAlign="center">
              <mat-spinner [color]="'accent'"></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>