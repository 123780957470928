<div fxLayout="row" fxLayoutAlign="center center">
  <div>
    <div class="loader-wrapper d-flex justify-content-center align-items-center">
      <div class="loader">
        <div class="ball-grid-beat">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>