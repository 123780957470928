<form [formGroup]="createUserForm">
  <div class="modal-body">
    <div class="h5 modal-title text-center">
      <h4 class="mt-2">
        <div>Crear cuenta</div>
        <!-- <span>Por favor inicie sesión en su cuenta.</span> -->
      </h4>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around space-between">
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
        <mat-form-field fxFlex="45%">
          <input type="text" matInput formControlName="nombre" placeholder="Nombre">
        </mat-form-field>
        <mat-form-field fxFlex="50%">
          <input type="text" matInput formControlName="apellido" placeholder="Apellido">
        </mat-form-field>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
        <mat-form-field fxFlex="45%">
          <mat-select formControlName="sexo" placeholder="Sexo">
            <mat-option value="F">Femenino</mat-option>
            <mat-option value="M">Masculino</mat-option>
            <mat-option value="I">Indefinido</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="50%">
          <mat-label>Fecha de Nacimiento</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="fecha_nacimiento">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <mat-form-field fxFlex>
        <input type="text" matInput formControlName="name" placeholder="Nombre de Usuario" autocomplete="username">
      </mat-form-field>
      <mat-form-field fxFlex>
        <input id="user" type="email" placeholder="Ingrese su email" aria-required="true" matInput autocomplete="email"
          formControlName="email">
        <mat-hint> Nunca compartiremos su correo electrónico con nadie más.</mat-hint>
        <mat-error
          *ngIf="createUserForm.controls.email.hasError('email') && !createUserForm.controls.email.hasError('required')">
          Por favor, ingrese una dirección de email valida.
        </mat-error>
        <mat-error *ngIf="createUserForm.controls.email.hasError('required')">
          El email es <strong>requerido</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex>
        <input id="password" type="password" placeholder="Ingrese su contraseña" aria-required="true" matInput
          formControlName="password" autocomplete="new-password">
        <mat-error
          *ngIf="createUserForm.get('password').hasError('required') && createUserForm.get('password').touched">
          Debe ingresar una contraseña.
        </mat-error>
        <mat-error
          *ngIf="createUserForm.get('password').hasError('minlength') && createUserForm.get('password').touched">
          Debe introducir al menos {{minpassword}} caracteres.
        </mat-error>
        <mat-error
          *ngIf="createUserForm.get('password').hasError('maxlength') && createUserForm.get('password').touched">
          Debe introducir sólo hasta {{maxpassword}} caracteres.
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex>
        <input id="passwordRep" type="password" placeholder="Repita su contraseña" aria-required="true" matInput
          formControlName="password_confirmation" autocomplete="new-password">
        <mat-error *ngIf="!createUserForm.get('password_confirmation').valid">
          Las contraseñas deben coincidir.
        </mat-error>
        <mat-error
          *ngIf="createUserForm.get('password_confirmation').hasError('minlength') && createUserForm.get('password_confirmation').touched">
          Debe introducir al menos {{minpassword}} caracteres.
        </mat-error>
        <mat-error
          *ngIf="createUserForm.get('password_confirmation').hasError('maxlength') && createUserForm.get('password_confirmation').touched">
          Debe introducir sólo hasta {{maxpassword}} caracteres.
        </mat-error>
      </mat-form-field>
    </div>

  </div>
  <div class="modal-footer clearfix">
    <div fxLayout="row">
      <button type="submit" class="btn btn-primary btn-lg"
        [disabled]="!createUserForm.valid || createUserForm.get('password').value !== createUserForm.get('password_confirmation').value "
        (click)="crearUsuario()">Crear
      </button>
      <button type="button" class="btn btn-light btn-lg ml-2" (click)="hide.emit(true)">Cancelar
      </button>
    </div>
  </div>
</form>