import {Injectable} from '@angular/core';
import {Globals} from '../../../../Globals';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ParametricosMenuBaseService {

  constructor(
    private http: HttpClient,
    private global: Globals) {
  }

  getCantidadPorItem(search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url: string = this.global.makeUrl('/dashboardsParametricos', search, pagination);
    return this.http.get(url, {headers});
  }
}
