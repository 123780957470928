import { NgxPermissionsService } from 'ngx-permissions';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
})
export class PageTitleComponent implements OnInit {

  @Input() heading;
  @Input() subheading;
  // Para Senadis, opcional tener subheading
  @Input() showSubheading;
  @Input() icon;
  // textButton es el texto que se mostrará dentro del botón
  @Input() textButton;
  // clicked es el evento que se disparará cuando el botón es clickeado
  @Output() clicked?: EventEmitter<boolean> = new EventEmitter<boolean>();
  // showButton permite la visualización del botón
  @Input() showButton: boolean;
  // nombrePermiso es el permiso asociado a la creación
  @Input() permissionCreate: string;
  fechaActual = new Date();

  constructor(private permissionService: NgxPermissionsService) {
    this.fechaActual;
  }

  ngOnInit() {
    this.verificarPermiso();
  }

  verificarPermiso() {
    if (this.permissionCreate) {
      this.permissionService.hasPermission(this.permissionCreate).then(result => {
        if (result) {
          this.showButton = true;
        } else {
          this.showButton = false;
        }
      });
    }
  }
}
