<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="row border-bottom" style="width: 100% !important">
      <div class="col-md-8 col-sm-12">
        <div class="page-title-heading">
          <!-- <div class="page-title-icon">
            <i class="icon {{icon}}"></i>
          </div>  SENADIS: Titulo de página sin icono   --> 
            {{heading}}
            <div class="page-title-subheading" *ngIf="showSubheading">
              {{subheading}}
            </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 d-flex justify-content-end">
        <div class="borde-fecha ml-5">
          <div class="d-flex justify-content-center align-items-center mt-1 w-100 mr-2 ml-2" style="height: 80%;">
            <i class="inline-icon material-icons mr-1 mb-1" style="transform: scale(0.8) !important;">
              date_range
              </i> <span class="mb-1 mr-3" style="font-size: 14px !important;"> {{fechaActual | date: 'd/M/yyyy'}} </span> 
          </div>
        </div>
      </div>
    </div> <!-- END ROW -->
  </div>
  <div class="row">
    <div class="col mt-4">
      <div class="page-title-actions" *ngIf="showButton">
        <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-dark" 
                (click)="clicked.emit(true)" style="border-radius: 3rem;">
          <i class="mr-2 fa fa-plus"></i>
          {{textButton}}
        </button>
      </div>
    </div>
  </div>
</div>
