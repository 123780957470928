import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../../../Globals';

@Injectable({
  providedIn: 'root'
})
export class UsuariosServices {

  constructor(private http: HttpClient, private global: Globals) {
  }

  getUsuario(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/users/' + id;
    return this.http.get(url, { headers });
  }

  getUsuarios(search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/users', search, pagination);
    return this.http.get(url, { headers });
  }

  crearUsuario(formData: FormData) {
    const url = this.global.API_ENDPOINT_ES + '/users';
    return this.http.post(url, formData);
  }

  editarUsuario(id, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/users/' + id;
    return this.http.put(url, jsonData, { headers });
  }

  editarUsuarioFoto(id, formData: FormData) {
    const url = this.global.API_ENDPOINT_ES + '/users/' + id + '/foto';
    return this.http.post(url, formData);
  }

  getRoles() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboRoles';
    return this.http.get(url, { headers });
  }

  getPersonas(usuario = false) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/comboPersonas' + (usuario ? '?withoutUser=true' : '');
    return this.http.get(url, { headers });
  }

  borrarUsuario(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/users/' + id;
    return this.http.delete(url, { headers });
  }
  // Agregar Persona
  crearPersona(jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/personas';
    return this.http.post(url, jsonData, { headers });
  }

  enviarCorreoRestablecerCuenta(id) {
    const url = this.global.API_ENDPOINT_ES + '/users/' + id + '/passwords';
    return this.http.post(url, null);
  }

  // Reportes

  getReporteUsuarios(search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl(`/users/exportar`, search, pagination);
    return this.http.get(url, {headers, responseType: 'blob'});
  }
}
