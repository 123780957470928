import { FilesService } from './../../../../../../util/services/files/files.service';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/auth/user';
import { UsuarioPerfilResponse } from 'src/app/Pages/Administracion/usuarios/interfaces/usuarios-response.interface';
import { Globals } from 'src/app/Globals';
import { UserProfileService } from './services/user-profile.service';
import { PerfilResponse } from './interfaces/perfil-response.interface';
import { Perfil } from './interfaces/perfil.interface';
export interface URL {
  url_image: string;
}
@Component({
  selector: 'app-user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.sass']
})

export class UserProfileDialogComponent implements OnInit {
  cargando: boolean;
  usuario: User;

  perfil: Perfil;
  public perfilForm: FormGroup;
  public disabled = true;
  showUserSettings = false;
  showInformacion = true;

  noEditable;
  imageToShowDialog: any;
  file: any;
  public newFotoForm: FormGroup;

  constructor(
    private router: Router,
    private userProfileServices: UserProfileService,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private formStatus: FormStatusService,
    private errorService: ErrorService,
    private toastr: ToastrService,
    private route: ActivatedRoute, public dialogRef: MatDialogRef<UserProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: URL, private globals: Globals, private fileService: FilesService) {
  }

  ngOnInit() {
    // this.noEditable = ['active', 'acceso_web', 'acceso_app'];
    this.usuario = this.localStorageService.getUserFromStorage();
    this.loadForm();
    this.getPerfil();
  }

  loadForm() {
    this.perfilForm = this.formBuilder.group({
      id: null,
      adm_persona_id: { value: '', disabled: this.disabled },
      active: { value: '', disabled: this.disabled },
      name: [{ value: '', disabled: this.disabled }, [Validators.maxLength(8)]],
      email: [{ value: '', disabled: this.disabled }, [Validators.email]],
      acceso_web: { value: '', disabled: this.disabled },
      acceso_app: { value: '', disabled: this.disabled },
      nombre: { value: '', disabled: this.disabled },
      apellido: { value: '', disabled: this.disabled },
      foto_usuario: { value: '', disabled: this.disabled }
    });
  }
  getPerfil() {
    this.userProfileServices.getPerfil()
      .subscribe((respuesta: PerfilResponse) => {
        this.perfil = respuesta.data;
        this.perfilForm.patchValue(respuesta.data);
        this.perfilForm.controls.foto_usuario.reset();
        this.imageToShowDialog = this.globals.FILE_ENDPOINT + respuesta.data.foto_usuario;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  volver() {
    this.dialogRef.close(this.imageToShowDialog);
  }

  onNoClick(): void {
    this.dialogRef.close(this.imageToShowDialog);
  }

  fileChangeEvent(files): void {
    this.file = files[0];
    const formData = new FormData();
    if (this.file) {
      formData.append('file', this.file, this.file.name);
      if (this.usuario.userData.foto_perfil) {
        formData.append('method', 'PUT');
      } else {
        formData.append('method', 'POST');
      }
      formData.append('id', this.usuario.userData.user_id.toString());
      formData.append('entity', 'App\\Http\\Domain\\Entities\\User');
      formData.append('record', 'foto_usuario');
      formData.append('folder', 'usuarios');

    }
    this.cargando = true;
    this.fileService.subirArchivo(formData)
      .subscribe((respuesta: UsuarioPerfilResponse) => {
        if (respuesta.success) {
          this.toastr.success('Foto de usuario editada exitosamente', 'Edición de Usuario');
          this.getPerfil();
        } else {
          this.toastr.error('Error al editar foto de usuario', 'Edición de Usuario');
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => { this.cargando = false; });

  }

  /*  getProfilePicture(fileUrl: string) {
      this.fileService.getUserFiles(fileUrl).subscribe(respuesta => {
        this.createImageFromBlob(respuesta);
        this.cargando = false;
      }, error => {
        console.warn(' ¡Atención! No se ha encontrado la foto de perfil del usuario.');
        this.imageToShow = './assets/images/avatars/avatar-man.png';
      }).add(() => { this.cargando = false; });
    }
  
    createImageFromBlob(image: Blob) {
      let reader = new FileReader();
      reader.addEventListener('load', () => {
        this.imageToShow = reader.result;
      }, false);
  
      if (image) {
        reader.readAsDataURL(image);
      }
    }*/
}
