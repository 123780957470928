import { AdministracionComponent } from './Pages/Administracion/administracion.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { UsuariosComponent } from './Pages/Administracion/usuarios/usuarios.component';
import { LoginComponent } from './Pages/Login/login/login.component';
import { PerfilComponent } from './Pages/Usuario/perfil/perfil.component';
import { DetalleUsuarioComponent } from './Pages/Administracion/usuarios/detalle-usuario/detalle-usuario.component';
import { CrearUsuarioComponent } from './Pages/Administracion/usuarios/crear-usuario/crear-usuario.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { HomeComponent } from './Pages/home/home.component';
import { RecuperarPasswordComponent } from './Pages/Login/login/recuperar-password/recuperar-password.component';
import { RecuperarExitoComponent } from './Pages/Login/login/recuperar-password/recuperar-exito/recuperar-exito.component';
import { TarjetasParametricosComponent } from './Pages/tarjetas-parametricos/tarjetas-parametricos.component';


const routes: Routes = [
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'recuperar', component: RecuperarPasswordComponent,
  },
  {
    path: 'recuperar/exito', component: RecuperarExitoComponent,
  },
  {
    path: '',
    component: BaseLayoutComponent,
    //canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'prefix'
      },
      // Administracion
      {
        path: 'inicio',
        component: HomeComponent,
        canActivate: [AuthGuardService],
        data: { extraParameter: 'inicioMenu' }
      },
      {
        path: 'administracion',
        canActivate: [AuthGuardService],
        children: [
          {
            path: '',
            component: AdministracionComponent
          },
          {
            path: 'usuarios',
            loadChildren: () => import('./Pages/Administracion/usuarios/usuarios.module').then(m => m.UsuariosModule),
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'modulos',
            loadChildren: () => import('./Pages/Administracion/modulos/modulos.module').then(m => m.ModulosModule),
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'roles',
            loadChildren: () => import('./Pages/Administracion/roles/roles.module').then(m => m.RolesModule),
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'auditoria',
            loadChildren: () => import('./Pages/Administracion/auditoria/auditoria.module').then(m => m.AuditoriaModule),
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'parametros-sistema',
            loadChildren: () => import('./Pages/Administracion/parametros-sistema/parametros-sistema.module').then(m => m.ParametrosSistemaModule),
            data: { extraParameter: 'administracionMenu' }
          },
          {
            path: 'parametricos',
            loadChildren: () => import('./Pages/Administracion/parametricos/parametricos.module').then(m => m.ParametricosModule),
            data: { extraParameter: 'administracionMenu' }
          }, {
            path: 'configuraciones-relojes',
            loadChildren: () => import('./Pages/Administracion/configuraciones-reloj/configuraciones-reloj.module').then(m => m.ConfiguracionesRelojModule),
            data: { extraParameter: 'administracionMenu' }
          }, {
            path: 'logs-relojes',
            loadChildren: () => import('./Pages/Administracion/logs-reloj/logs-reloj.module').then(m => m.LogsRelojModule),
            data: { extraParameter: 'administracionMenu' }
          },
        ],
        data: { extraParameter: 'administracionMenu' }
      },
      // Administracion
      { path: 'usuario/perfil-usuario', component: PerfilComponent, data: { extraParameter: 'administracionMenu' } },

      // Parametricos de Administración. Componente de tarjetas
      {
        path: 'parametricos',
        canActivate: [AuthGuardService],
        children: [
          {
            path: '',
            component: TarjetasParametricosComponent
          },
        ],
        data: { extraParameter: 'administracionMenu' }
      },
      // Notificaciones
      {
        path: 'notificaciones',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/Notificaciones/notificaciones.module').then(m => m.NotificacionesModule),
        data: { extraParameter: 'notificacionesMenu' }
      },
      {
        path: 'organizacion',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/organizacion/organizacion.module').then(m => m.OrganizacionModule),
        data: { extraParameter: 'organizacionMenu' }
      },
      {
        path: 'admision-seguimiento',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/admision-seguimiento/admision-seguimiento.module').then(m => m.AdmisionSeguimientoModule),
        data: { extraParameter: 'admisionMenu' }
      },
      {
        path: 'farmacia',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/farmacia/farmacia.module').then(m => m.FarmaciaModule),
        data: { extraParameter: 'farmaciaMenu' }
      },
      {
        path: 'servicios',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/servicio/servicio.module').then(m => m.ServicioModule),
        data: { extraParameter: 'servicioMenu' }
      },
      {
        path: 'archivo',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/archivo/archivo.module').then(m => m.ArchivoModule),
        data: { extraParameter: 'archivoMenu' }
      },
      {
        path: 'ayuda-social',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/trabajo-social/trabajo-social.module').then(m => m.TrabajoSocialModule),
        data: { extraParameter: 'trabajoSocialMenu' }
      },
      {
        path: 'laboratorio',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/laboratorio/laboratorio.module').then(m => m.LaboratorioModule),
        data: { extraParameter: 'laboratorioMenu' }
      },
      {
        path: 'transporte',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/transporte/transporte.module').then(m => m.TransporteModule),
        data: { extraParameter: 'transporteMenu' }
      },
      {
        path: 'ayuda-tecnica',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/ayuda-tecnica/ayuda-tecnica.module').then(m => m.AyudaTecnicaModule),
        data: { extraParameter: 'ayudaTecnicaMenu' }
      },
      {
        path: 'ventanilla',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/ventanilla/ventanilla.module').then(m => m.VentanillaModule),
        data: { extraParameter: 'ventanillaModule' }
      },
      {
        path: 'tesoreria',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/tesoreria/tesoreria.module').then(m => m.TesoreriaModule),
        data: { extraParameter: 'tesoreriaModule' }
      },
      {
        path: 'recursos-humanos',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/recursos-humanos/recursos-humanos.module').then(m => m.RecursosHumanosModule),
        data: { extraParameter: 'recursosHumanosModule' }
      },
      {
        path: 'contratos-suministros',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./Pages/contratos-suministros/contratos-suministros.module').then(m => m.ContratosSuministrosModule),
        data: { extraParameter: 'contratosSuministrosModule' }
      },
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      useHash: true
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
