<div class="h-100 bg-night-sky bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="app-logo-horizontal mx-auto mb-3"></div>
      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <div class="modal-body">
            <div class="h5 modal-title text-center">
              <h4 class="mt-2">
                <span>Recuperar Contraseña</span></h4>
            </div>
            <ng-template [ngIf]="!cargando" [ngIfElse]="showSpinner">
              <form [formGroup]="recuperarForm" (ngSubmit)="actualizarPassword()">
                <div role="group" class="form-group">
                  <div><input id="email" type="email" placeholder="Dirección de Email..." class="form-control" autocomplete="email"
                      formControlName="email">
                  </div>
                </div>
                <div id="exampleInputGroup1" role="group" aria-describedby="exampleInputGroup1__BV_description_"
                  class="form-group">
                  <div><input id="password" type="password" placeholder="Contraseña Nueva..." class="form-control"
                      formControlName="password" aria-describedby="exampleInputGroup1__BV_description_" autocomplete="new-password">
                      <!-- <div class="invalid-feedback"  *ngIf="recuperarForm.get('password').hasError('minlength') && recuperarForm.get('password').touched">
                        Debe introducir al menos {{parametrosIniciales.MINPASSWORD.MINPASSWORD}} caracteres.
                      </div>
                      <div class="invalid-feedback"  *ngIf="recuperarForm.get('password').hasError('maxlength') && recuperarForm.get('password').touched">
                        Debe introducir solo hasta {{parametrosIniciales.MAXPASSWORD.MAXPASSWORD}} caracteres.
                      </div> -->
                    <small tabindex="-1" id="exampleInputGroup1__BV_description_" class="form-text text-muted">
                    </small>
                  </div>
                </div>
                <div id="password_confirmation" role="group" class="form-group">
                  <div><input id="exampleInput2" type="password" placeholder="Repetir Contraseña Nueva..." autocomplete="new-password"
                      class="form-control" formControlName="password_confirmation">
                  </div>
                </div>
              </form>
            </ng-template>
            <ng-template #showSpinner>
              <app-spinner></app-spinner>
            </ng-template>
          </div>
          <div class="modal-footer clearfix" *ngIf="!cargando">
            <div class="float-right">
              <button type="submit" class="btn btn-primary btn-lg" [disabled]="!recuperarForm.valid" (click)="actualizarPassword()">
                Guardar
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>