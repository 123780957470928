<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
  (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <div [class]="!globals.toggleSidebar ? 'logo-src' : 'logo-contraido-src'" (click)="toggleSidebar()"
      style="cursor: pointer"></div>
    <div class="header__pane ml-auto">
      <div class="mb-5">
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
          [ngClass]="{'is-active' : !globals.toggleSidebar}" (click)="toggleSidebar()">
          <span class="material-icons" style="color: gray; font-size: 40px !important;">
            arrow_back
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [config]="config">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">

            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header" *ngIf="hasPanelHeaderTitle">Módulo Principal</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="administracionMenu" *ngxPermissionsOnly="[
                                        'ADM/mant_usuarios/listListarUsuarios',
                                        'ADM/mant_roles/listListarRolesdelSistema',
                                        'ADM/mant_parametros/listListarParámetrosdelSistema',
                                        'ADM/mant_modulos/listListarMódulosdelSistema',
                                        'ADM/mant_auditoria/listListarIngresosalsistema',
                                        'ADM/mant_auditoria/listListarauditoriadeoperaciones',
                                        'ADM/mant_configuraciones_relojes/list285'
                                        ]">
                <ng-template ngbPanelHeader>

                  <button ngbPanelToggle class="vsm-link" routerLink="/administracion" routerLinkActive="active-item"
                    style="margin-top: 0 !important;">
                    <i class="vsm-icon pe-7s-display1"></i>
                    <!-- <a routerLink="/administracion" routerLinkActive="active-item">
                      </a> -->
                    <span class="vsm-title">Administración</span>
                    <i class="vsm-arrow"></i>
                  </button>


                </ng-template>
                <ng-template ngbPanelContent>

                  <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_usuarios/listListarUsuarios'">
                    <a routerLink="/administracion/usuarios" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-user"></i>
                      <span class="vsm-title">Usuarios</span>
                    </a>
                  </div>

                  <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_roles/listListarRolesdelSistema'">
                    <a routerLink="/administracion/roles" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-study"></i>
                      <span class="vsm-title">Roles</span>
                    </a>
                  </div>

                  <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_parametros/listListarParámetrosdelSistema'">
                    <a routerLink="/administracion/parametros-sistema" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-science"></i>
                      <span class="vsm-title">Parámetros del Sistema</span>
                    </a>
                  </div>

                  <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_modulos/listListarMódulosdelSistema'">
                    <a routerLink="/administracion/modulos" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-network"></i>
                      <span class="vsm-title">Módulos</span>
                    </a>
                  </div>

                  <div class="vsm-item"
                    *ngxPermissionsOnly="['ADM/mant_auditoria/listListarIngresosalsistema', 'ADM/mant_auditoria/listListarauditoriadeoperaciones']">
                    <a routerLink="/administracion/auditoria" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-news-paper"></i>
                      <span class="vsm-title">Auditoría</span>
                    </a>
                  </div>

                  <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_configuraciones_relojes/list285'">
                    <a routerLink="/administracion/configuraciones-relojes" routerLinkActive="active-item"
                      class="vsm-link" style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-clock"></i>
                      <span class="vsm-title">Configuraciones de relojes</span>
                    </a>
                  </div>

                  <div class="vsm-item" *ngxPermissionsOnly="'ADM/mant_logs_relojes/list290'">
                    <a routerLink="/administracion/logs-relojes" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-clock"></i>
                      <span class="vsm-title">Logs de relojes</span>
                    </a>
                  </div>

                </ng-template>
              </ngb-panel>

            </ngb-accordion>

            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="administracionMenu" *ngIf="permissionController.modulosObj['PAR']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" routerLink="/parametricos" routerLinkActive="active-item"
                    style="margin: 0 !important;">
                    <i class="vsm-icon pe-7s-graph1"></i>
                    <span class="vsm-title">Paramétricos</span>
                  </button>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>

            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="organizacionMenu" *ngIf="permissionController.modulosObj['ORG']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" routerLink="/organizacion" routerLinkActive="active-item"
                    style="margin: 0 !important;">
                    <i class="vsm-icon pe-7s-keypad"></i>
                    <span class="vsm-title">Organización</span>
                  </button>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>

            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header" *ngIf="hasPanelHeaderTitle">Módulo Principal</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="admisionMenu" *ngxPermissionsOnly="'ADMI/mant_paciente/list500'">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" style="margin-top: 0 !important;">
                    <i class="vsm-icon pe-7s-star"></i>
                    <span class="vsm-title">Admisión</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-item">
                    <a routerLink="/admision-seguimiento" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-add-user"></i>
                      <span class="vsm-title">Admisión y Seguimiento</span>
                    </a>
                  </div>
                  <div class="vsm-item">
                    <a routerLink="/admision-seguimiento/reportes" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-check"></i>
                      <span class="vsm-title">Reportes</span>
                    </a>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="farmaciaMenu" *ngIf="permissionController.modulosObj['FAR']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" style="margin-top: 0 !important;">
                    <i class="vsm-icon pe-7s-bandaid"></i>
                    <span class="vsm-title">Farmacia</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-item" *ngxPermissionsOnly="'FAR/mant_entrada_cabecera/list700'">
                    <a routerLink="/farmacia/entrada-productos" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-note2"></i>
                      <span class="vsm-title">Entrada de Productos</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'FAR/mant_salida_cabecera/list720'">
                    <a routerLink="/farmacia/salida-productos" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-paper-plane"></i>
                      <span class="vsm-title">Salida de Productos</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'FAR/viz_medicamentos_recibidos/get734'">
                    <a routerLink="/farmacia/medicamentos-retirados" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-search"></i>
                      <span class="vsm-title">Medicamentos Retirados</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'FAR/mant_existencia/list715'">
                    <a routerLink="/farmacia/existencias" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-info"></i>
                      <span class="vsm-title"> Existencias </span>
                    </a>
                  </div>
                  <div class="vsm-item">
                    <a routerLink="/farmacia/reportes" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-check"></i>
                      <span class="vsm-title"> Reportes </span>
                    </a>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="archivoMenu" *ngIf="permissionController.modulosObj['ARC']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" style="margin-top: 0 !important;">
                    <i class="vsm-icon pe-7s-notebook"></i>
                    <span class="vsm-title">Archivo</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-item" *ngxPermissionsOnly="'ARC/mant_movimiento/list900'">
                    <a routerLink="/archivo/movimientos" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-refresh-2"></i>
                      <span class="vsm-title">Movimientos</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'ARC/mant_movimiento_historial/list905'">
                    <a routerLink="/archivo/historial-movimientos" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-paper-plane"></i>
                      <span class="vsm-title">Historial de Movimientos</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'ARC/mant_archivo_cabecera_paciente/list910'">
                    <a routerLink="/archivo/pacientes-archivos" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-cloud-upload"></i>
                      <span class="vsm-title">Registro de Archivos</span>
                    </a>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="servicioMenu" *ngIf="permissionController.modulosObj['SER']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" style="margin-top: 0 !important;">
                    <i class="vsm-icon pe-7s-copy-file"></i>
                    <span class="vsm-title">Servicios</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-item" *ngxPermissionsOnly="'SER/mant_turno/list1000'">
                    <a routerLink="/servicios/turnos" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-note2"></i>
                      <span class="vsm-title">Turnos</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'SER/mant_consulta/list1015'">
                    <a routerLink="/servicios/consultas" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-chat"></i>
                      <span class="vsm-title">Consultas</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'SER/mant_receta/list1005'">
                    <a routerLink="/servicios/recetas" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-shield"></i>
                      <span class="vsm-title">Recetas</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'SER/mant_servicio/list1020'">
                    <a routerLink="/servicios/registrar-servicios" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-help2"></i>
                      <span class="vsm-title">Otros Servicios</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'SER/mant_certificado/list1025'">
                    <a routerLink="/servicios/certificaciones" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-study"></i>
                      <span class="vsm-title">Certificaciones</span>
                    </a>
                  </div>
                  <div class="vsm-item">
                    <a routerLink="/servicios/reportes" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-check"></i>
                      <span class="vsm-title">Reportes</span>
                    </a>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="laboratorioMenu" *ngIf="permissionController.modulosObj['LAB']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" style="margin-top: 0 !important;">
                    <i class="vsm-icon pe-7s-tools"></i>
                    <span class="vsm-title">Laboratorio</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-item" *ngxPermissionsOnly="'LAB/mant_analisis_cabecera/list1100'">
                    <a routerLink="/laboratorio/analisis" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-note2"></i>
                      <span class="vsm-title">Agendamiento de Análisis</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'LAB/mant_analisis_cabecera/list1186'">
                    <a routerLink="/laboratorio/registro-resultados" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-news-paper"></i>
                      <span class="vsm-title">Registro de Resultados</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'LAB/mant_analisis_cabecera/list1187'">
                    <a routerLink="/laboratorio/entrega-resultados" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-eyedropper"></i>
                      <span class="vsm-title">Entrega de Resultados</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'LAB/mant_entrada_cabecera_insumo_laboratorio/list1120'">
                    <a routerLink="/laboratorio/entradas" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-check"></i>
                      <span class="vsm-title">Entradas</span>
                    </a>
                  </div>
                  <!-- <div class="vsm-item">
                    <a routerLink="/laboratorio/remisiones" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-target"></i>
                      <span class="vsm-title">Remisiones</span>
                    </a>
                  </div>
                  <div class="vsm-item">
                    <a routerLink="/laboratorio/facturas" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-plugin"></i>
                      <span class="vsm-title">Facturas</span>
                    </a>
                  </div> -->
                  <div class="vsm-item" *ngxPermissionsOnly="'LAB/mant_consumo_cabecera_laboratorio/list1150'">
                    <a routerLink="/laboratorio/salidas" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-back"></i>
                      <span class="vsm-title">Salidas</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'LAB/mant_existencia_laboratorio/list1160'">
                    <a routerLink="/laboratorio/existencias" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-albums"></i>
                      <span class="vsm-title">Existencias</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'LAB/mant_radiologia_cabecera/list1110'">
                    <a routerLink="/laboratorio/radiologia" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-note2"></i>
                      <span class="vsm-title">Radiología</span>
                    </a>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="transporteMenu" *ngIf="permissionController.modulosObj['TRANS']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" style="margin-top: 0 !important;">
                    <i class="vsm-icon pe-7s-car"></i>
                    <span class="vsm-title">Transporte</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-item" *ngxPermissionsOnly="'TRANS/mant_agendamiento/list1300'">
                    <a routerLink="/transporte/agendamiento" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-map-2"></i>
                      <span class="vsm-title">Agendamiento</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'TRANS/mant_recorrido/list1305'">
                    <a routerLink="/transporte/recorridos" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-map-2"></i>
                      <span class="vsm-title">Recorridos</span>
                    </a>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="trabajoSocialMenu" *ngIf="permissionController.modulosObj['AYUSOC']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" style="margin-top: 0 !important;">
                    <i class="vsm-icon pe-7s-target"></i>
                    <span class="vsm-title">Ayuda Social</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-item" *ngxPermissionsOnly="'ADMI/mant_paciente/list500'">
                    <a routerLink="/ayuda-social/ficha-social" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-id"></i>
                      <span class="vsm-title">Ficha Social</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'AYUSOC/mant_solicitud/list1540'">
                    <a routerLink="/ayuda-social/solicitudes" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-wallet"></i>
                      <span class="vsm-title">Solicitudes</span>
                    </a>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="ayudaTecnicaMenu" *ngIf="permissionController.modulosObj['AYUTEC']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" style="margin-top: 0 !important;">
                    <i class="vsm-icon pe-7s-plugin"></i>
                    <span class="vsm-title">Ayuda Técnica</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-item">
                    <a routerLink="/ayuda-tecnica/solicitudes" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-note2"></i>
                      <span class="vsm-title">Solicitudes Pendientes</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'AYUTEC/mant_entrada_ayuda_tecnica/list1700'">
                    <a routerLink="/ayuda-tecnica/registro-entradas" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-upload"></i>
                      <span class="vsm-title">Entradas</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'AYUTEC/mant_acta_entrega/list1715'">
                    <a routerLink="/ayuda-tecnica/registro-actas-entrega" routerLinkActive="active-item"
                      class="vsm-link" style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-note"></i>
                      <span class="vsm-title"> Actas de Entrega</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'AYUTEC/mant_salida_temporal/list1720'">
                    <a routerLink="/ayuda-tecnica/registro-salidas-temporales" routerLinkActive="active-item"
                      class="vsm-link" style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-download"></i>
                      <span class="vsm-title"> Salidas Temporales</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'AYUTEC/mant_existencia_ayuda_tecnica/list1710'">
                    <a routerLink="/ayuda-tecnica/existencia" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-download"></i>
                      <span class="vsm-title"> Existencias</span>
                    </a>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="ventanillaMenu" *ngIf="permissionController.modulosObj['VENTAN']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" style="margin-top: 0 !important;">
                    <i class="vsm-icon pe-7s-network"></i>
                    <span class="vsm-title">Ventanilla</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-item">
                    <a routerLink="/ventanilla/solicitudes-ventanilla" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-bell"></i>
                      <span class="vsm-title"> Solicitudes</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'VENTAN/mant_compromiso_transferencia/list1800'">
                    <a routerLink="/ventanilla/compromiso-transferencia" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-shuffle"></i>
                      <span class="vsm-title">Compromiso de Transferencia</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'VENTAN/mant_orden_pago/list1810'">
                    <a routerLink="/ventanilla/ordenes-pago" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-next-2"></i>
                      <span class="vsm-title"> Orden de Pago</span>
                    </a>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="tesoreriaMenu" *ngIf="permissionController.modulosObj['TESORE']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" style="margin-top: 0 !important;">
                    <i class="vsm-icon pe-7s-piggy"></i>
                    <span class="vsm-title">Tesoreria</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-item" *ngxPermissionsOnly="'TESORE/mant_cheque/list1900'">
                    <a routerLink="/tesoreria/cheques" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-cash"></i>
                      <span class="vsm-title">Cheques</span>
                    </a>
                  </div>
                  <div class="vsm-item">
                    <a routerLink="/tesoreria/facturas" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-credit"></i>
                      <span class="vsm-title">Facturas</span>
                    </a>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="recursosHumanosMenu" *ngIf="permissionController.modulosObj['RRHH']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" style="margin-top: 0 !important;">
                    <i class="vsm-icon pe-7s-users"></i>
                    <span class="vsm-title">Recursos Humanos</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-item" *ngxPermissionsOnly="'RRHH/mant_personas_rrhh/list3000'">
                    <a routerLink="/recursos-humanos/personas" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-id"></i>
                      <span class="vsm-title">Funcionarios</span>
                    </a>
                  </div>
                  <!--                  <div class="vsm-item" *ngxPermissionsOnly="'RRHH/mant_personas_rrhh/list3000'">-->
                  <!--                    <a routerLink="/recursos-humanos/liquidaciones" routerLinkActive="active-item" class="vsm-link"-->
                  <!--                      style="margin-top: 0 !important;">-->
                  <!--                      <i class="vsm-icon pe-7s-cash"></i>-->
                  <!--                      <span class="vsm-title">Liquidaciones</span>-->
                  <!--                    </a>-->
                  <!--                  </div>-->
                  <div class="vsm-item" *ngxPermissionsOnly="'RRHH/mant_instancia_evaluacion_cabecera/list3064'">
                    <a routerLink="/recursos-humanos/evaluaciones" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-note2"></i>
                      <span class="vsm-title">Evaluaciones</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="[
                                        'SUM/mant_solicitud_entrega/get3255',
                                        'SUM/mant_existencia_insumo/list3256'
                                        ]">
                    <a routerLink="/recursos-humanos/reportes" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-note2"></i>
                      <span class="vsm-title">Reportes</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'RRHH/mant_incidencia/list3140'">
                    <a routerLink="/recursos-humanos/incidencias" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-timer"></i>
                      <span class="vsm-title">Incidencias</span>
                    </a>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>

            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter"
              *ngxPermissionsOnly="'RRHH/mant_personas_rrhh/list3000'">
              <ngb-panel id="liquidacionesMenu" *ngIf="permissionController.modulosObj['RRHH']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" routerLink="/recursos-humanos/liquidaciones"
                    routerLinkActive="active-item" style="margin: 0 !important;">
                    <i class="vsm-icon pe-7s-cash"></i>
                    <span class="vsm-title">Liquidaciones</span>
                  </button>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>

            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel id="contratosSuministrosModule" *ngIf="permissionController.modulosObj['SUMIN']">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link" style="margin-top: 0 !important;">
                    <i class="vsm-icon pe-7s-piggy"></i>
                    <span class="vsm-title">Contratos y suministros</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-item" *ngxPermissionsOnly="'SUM/mant_entrada_cabecera_insumo/list3200'">
                    <a routerLink="/contratos-suministros/entradas" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-cash"></i>
                      <span class="vsm-title">Entradas</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'SUM/mant_remision_insumo/list3210'">
                    <a routerLink="/contratos-suministros/remisiones" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-shuffle"></i>
                      <span class="vsm-title">Remisiones</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'SUM/mant_factura_insumo/list3205'">
                    <a routerLink="/contratos-suministros/facturas" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-piggy"></i>
                      <span class="vsm-title">Facturas</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'SUM/mant_solicitud_cabecera_insumo/list3225'">
                    <a routerLink="/contratos-suministros/solicitudes-insumos" routerLinkActive="active-item"
                      class="vsm-link" style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-note2"></i>
                      <span class="vsm-title">Solicitudes</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'SUM/mant_solicitud_entrega/list3235'">
                    <a routerLink="/contratos-suministros/solicitudes-entregas" routerLinkActive="active-item"
                      class="vsm-link" style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-paper-plane"></i>
                      <span class="vsm-title">Entregas</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'SUM/mant_aprobacion/list3230'">
                    <a routerLink="/contratos-suministros/solicitudes-aprobaciones" routerLinkActive="active-item"
                      class="vsm-link" style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-check"></i>
                      <span class="vsm-title">Aprobaciones</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'SUM/mant_existencia_insumo/list3240'">
                    <a routerLink="/contratos-suministros/existencias" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-info"></i>
                      <span class="vsm-title">Existencias</span>
                    </a>
                  </div>
                  <div class="vsm-item" *ngxPermissionsOnly="'SUM/mant_baja_cabecera_insumo/list3257'">
                    <a routerLink="/contratos-suministros/bajas-insumos" routerLinkActive="active-item" class="vsm-link"
                      style="margin-top: 0 !important;">
                      <i class="vsm-icon pe-7s-download"></i>
                      <span class="vsm-title">Bajas de insumos</span>
                    </a>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>