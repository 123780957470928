<app-page-title [heading]="heading" 
                [subheading]="subheading"
                [showSubheading]="false" 
                [icon]="icon">
</app-page-title>
<div class="container-fluid">
  <ng-template [ngIf]="!cargando && adminDasboard" [ngIfElse]="showSpinner">
      <app-tarjetas-dashboard [tarjetas]="tarjetas">
      </app-tarjetas-dashboard>
  </ng-template>
  <ng-template #showSpinner>
    <app-spinner></app-spinner>
  </ng-template>
</div>

