import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-tarjetas-dashboard',
  templateUrl: './tarjetas-dashboard.component.html',
  styleUrls: ['./tarjetas-dashboard.component.sass']
})
export class TarjetasDashboardComponent implements OnInit {

  @Input() tarjetas = [];
  // El permiso de la visualizacion de una tarjeta ya se verifica en el objeto "tarjetas", donde se le pasa el permiso individualmente
  @Input() checkPermiso: boolean;
  @Input() permisoVer: string;

  constructor(private router: Router,
              private permissionService: NgxPermissionsService) { }

  ngOnInit(): void {
    //this.verificarPermiso();
  }

  navigate(url){
    this.router.navigateByUrl(url);
  }

  verificarPermiso() {
    if (this.permisoVer) {
      this.permissionService.hasPermission(this.permisoVer).then(result => {
        this.checkPermiso = result;
        console.log('Permiso chequeado: ', this.checkPermiso);
      });
    }
  }

}
