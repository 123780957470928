<div class="row mt-12">
  <div class="col-md-12 col-lg-12 col-xl-12" *ngIf="perfil">
    <div class="card-hover-shadow profile-responsive card-border border-success mb-3 card">
      <div class="dropdown-menu-header">
        <div class="dropdown-menu-header-inner bg-success">
          <div class="menu-header-content">
            <div class="avatar-icon-wrapper btn-hover-shine mb-2 avatar-icon-xl">
              <div class="avatar-icon rounded">
                <ng-template [ngIf]="perfil.foto_usuario">
                  <img [src]="perfil.foto_usuario" alt="Avatar" *ngIf="perfil.foto_usuario">
                </ng-template>
                <ng-template [ngIf]="!perfil.foto_usuario">
                  <img src="./assets/images/avatars/gender-neutral-user.png"
                       alt="">
                </ng-template>
              </div>
            </div>
            <div><h5 class="menu-header-title">{{perfil.nombre}}</h5>
              <h6 class="menu-header-subtitle">{{perfil.apellido}}</h6></div>
          </div>
        </div>
      </div>
      <div class="p-0 pb-0 card-body">
        <div class="main-card mb-0 card">
          <div class="card-body"><h5 class="card-title">Tus Datos</h5>
            <form [formGroup]="usuarioForm">
              <div class="form-row">
                <div class="col-md-6">
                  <div class="position-relative form-group"><label for="activo">Activo</label><select
                    name="select" id="activo" class="form-control" formControlName="active">
                    <option [ngValue]="true">Sí</option>
                    <option [ngValue]="false">No</option>
                  </select></div>
                </div>
                <div class="col-md-6">
                  <div class="position-relative form-group"><label for="userName">Nombre de Usuario</label>
                    <input type="text" placeholder="Nombre de Usuario" class="form-control" id="userName"
                           name="name"
                           formControlName="name">
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6">
                  <div class="position-relative form-group"><label for="email">Email</label>
                    <input type="text" placeholder="Email" class="form-control" id="email" name="email"
                           formControlName="email">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="position-relative form-group"><label for="exampleFile">Foto de Perfil</label>
                    <input name="file" id="exampleFile" type="file" class="form-control-file"
                           formControlName="foto_usuario">
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6">
                  <div class="position-relative form-group"><label for="password">Contraseña</label>
                    <input value="" type="password" placeholder="Contraseña" class="form-control" id="password"
                           name="password"
                           formControlName="password">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="position-relative form-group"><label for="password2">Repita Contraseña</label>
                    <input value="" type="password" placeholder="Contraseña" class="form-control" id="password2"
                           name="password2"
                           formControlName="password_rep">
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-6">
                  <label for="accesos">Accesos</label>
                  <div class="position-relative form-group" id="accesos">
                    <div class="custom-checkbox custom-control custom-control-inline">
                      <input type="checkbox"
                             id="exampleCustomInline"
                             class="custom-control-input" formControlName="acceso_web"><label
                      for="exampleCustomInline" class="custom-control-label">Web</label></div>
                    <div class="custom-checkbox custom-control custom-control-inline">
                      <input type="checkbox"
                             id="exampleCustomInline2"
                             class="custom-control-input" formControlName="acceso_app"><label
                      for="exampleCustomInline2" class="custom-control-label">App</label></div>
                  </div>
                </div>
              </div>
            </form>
            <div class="button-row text-center">
              <button class="mr-2 btn-shadow-primary btn btn-primary btn-lg" (click)="editableSwitcher()"
                      *ngIf="disabled">
                Editar
              </button>
              <button class="mr-2 btn-shadow-primary btn btn-primary btn-lg" (click)="validar()" *ngIf="!disabled"
                      [disabled]="!usuarioForm.valid">Guardar
              </button>
              <button class="btn-shadow-primary btn btn-primary btn-lg" (click)="volver()" *ngIf="disabled">Volver al
                Inicio
              </button>
              <button class="btn-shadow-primary btn btn-primary btn-lg" (click)="editableSwitcher()" *ngIf="!disabled">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


