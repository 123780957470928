import { AdministracionDashboardResponse } from './interfaces/administracion-dashboard-response';
import { ErrorService } from './../../util/services/error.service';
import { AdministracionDashboardService } from './services/administracion-dashboard.service';
import { Component, OnInit } from '@angular/core';
import { AdministracionDashboard } from './interfaces/administracion-dashboard';

@Component({
  selector: 'app-administracion',
  templateUrl: './administracion.component.html',
  styleUrls: ['./administracion.component.sass']
})
export class AdministracionComponent implements OnInit {

  heading = 'Administración';
  subheading = 'En este apartado de encuentran todas las informaciones administrativas del Sistema';
  icon = 'pe-7s-display1 icon-gradient bg-happy-fisher';
  adminDasboard: AdministracionDashboard;
  cargando = false;
  tarjetas = [];



  constructor(private adminDashboardService: AdministracionDashboardService,
    private errorService: ErrorService) { }

  ngOnInit() {
    this.getDashboard();
  }

  getDashboard() {
    this.cargando = true;
    this.adminDashboardService.getAdministracionDashboard().subscribe((respuesta: AdministracionDashboardResponse) => {
      if (respuesta.success) {
        this.adminDasboard = respuesta.data;
      }
    }, err => {
      this.errorService.handleError(err);
    }).add(() => {
      this.addTarjetas();
      this.cargando = false;
    });
  }

  addTarjetas() {
    this.tarjetas = [
      // { url: '/recursos-humanos/personas', titulo: 'Funcionarios', subtitulo: 'Total', contador: this.adminDasboard.cntPersonas, permiso: 'ADM/mant_personas/listListarPersonas' },
      { url: '/administracion/usuarios', titulo: 'Usuarios', subtitulo: 'Total', contador: this.adminDasboard.cntUsuarios, permiso: 'ADM/mant_usuarios/listListarUsuarios' },
      { url: '/administracion/parametros-sistema', titulo: 'Parámetros del Sistema', subtitulo: 'Total', contador: this.adminDasboard.cntParametros, permiso: 'ADM/mant_parametros/listListarParámetrosdelSistema' },
      { url: '/administracion/roles', titulo: 'Roles', subtitulo: 'Total', contador: this.adminDasboard.cntRoles, permiso: 'ADM/mant_roles/listListarRolesdelSistema' },
      { url: '/administracion/auditoria', titulo: 'Registros de Auditorías', subtitulo: 'Total', contador: this.adminDasboard.cntAuditorias, permiso: 'ADM/mant_auditoria/listListarIngresosalsistema' },
      // { url: '/administracion/parametricos/paises', titulo: 'Países', subtitulo: 'Total', contador: this.adminDasboard.cntPaises },
      // { url: '/administracion/parametricos/paises', titulo: 'Departamentos', subtitulo: 'Total', contador: this.adminDasboard.cntDepartamentos },
      // { url: '/administracion/parametricos/paises', titulo: 'Ciudades', subtitulo: 'Total', contador: this.adminDasboard.cntCiudades },
    ]
  }

}
