import { Injectable } from '@angular/core';
import { LocalStorageService } from './util/local-storage.service';
import { HttpHeaders } from '@angular/common/http';
import { ParametrosData } from './Pages/Login/interfaces/parametros';

@Injectable()
export class Globals {
  /* ESOLUTIONS BASEBACKEND */
  // API_ENDPOINT_ES = 'http://esolutions.com.py:8080/basebackend/api';
  // FILE_ENDPOINT = 'http://esolutions.com.py:8080/basebackend';
  /* DESWEDD BASEBACKEND */
  // API_ENDPOINT_ES = 'http://deswedd.espy.cloud:9568/apisenadis/api/v2';
  // FILE_ENDPOINT = 'http://deswedd.espy.cloud:9568/apisenadis';
  API_ENDPOINT_ES = 'https://apisenadis.espy.cloud/api/v2';
  FILE_ENDPOINT = 'https://apisenadis.espy.cloud';

  /* DESWEDD SENADIS MIGRACION */
  // API_ENDPOINT_ES = 'http://deswedd.espy.cloud:9568/apisenadismigracion/api/v2';
  // FILE_ENDPOINT = 'http://deswedd.espy.cloud:9568/apisenadismigracion';
  API_ENDPOINT = './assets/data/';
  API_ENDPOINTTEST = 'http://192.168.1.124/php-firebase-onesignal';

  public parametros: ParametrosData[];

  /* Habilita la creación de usuarios desde la ventana del login */
  registroDeUsuarios = true;

  constructor(private localStorageService: LocalStorageService) {
  }

  getHeader(token = null) {
    return new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      });
  }

  makeUrl(nombreServicio: string, search: any | undefined, pagination: any | undefined): string {
    let url: string = this.API_ENDPOINT_ES + nombreServicio;
    const params = [];

    for (const prop in search) {
      if (search[prop] !== undefined && search[prop] !== null && search[prop] !== '') {
        params.push(prop + '=' + search[prop]);
      }
    }

    for (const prop in pagination) {
      if (pagination[prop] !== undefined) {
        params.push(prop + '=' + pagination[prop]);
      }
    }

    if (params.length) {
      url += '?' + params.join('&');
    }

    return url;
  }

}
