<form [formGroup]="forgotPassForm">
  <div class="modal-body">
    <div class="h5 modal-title text-center">
      <h4 class="mt-2">
        <div class="mb-3">Recuperar tu cuenta</div>
        <span>
          <p style="text-align: justify;">Podemos ayudarle a restablecer tu contraseña. Escribe tu <span *ngIf="loginType === 'email'">email</span>
            <span *ngIf="loginType === 'doc'">número de documento</span> con el cuál te registraste
            en el sistema y envia la solicitud. Recibirás un correo electrónico al email asociado a tu usuario con las
            instrucciones.</p>
        </span>
      </h4>
    </div>
    <ng-template #username [ngIf]="showUsuario">
      <div fxLayout="column" fxLayoutAlign="space-around space-between">
        <mat-form-field fxFlex *ngIf="loginType === 'email'">
          <input id="user" type="email" placeholder="Ingrese su email" aria-required="true" matInput
            formControlName="email">
          <mat-hint> Nunca compartiremos su correo electrónico con nadie más.</mat-hint>
          <mat-error
            *ngIf="forgotPassForm.controls.email.hasError('email') && !forgotPassForm.controls.email.hasError('required')">
            Por favor, ingrese una dirección de email valida.
          </mat-error>
          <mat-error *ngIf="forgotPassForm.controls.email.hasError('required')">
            El email es <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex *ngIf="loginType === 'doc'">
          <input id="user" type="text" placeholder="Ingrese su número de documento" aria-required="true" matInput
            formControlName="doc">
          <mat-hint> Nunca compartiremos sus datos con nadie más.</mat-hint>
          <mat-error *ngIf="forgotPassForm.controls.doc.hasError('required')">
            El número de documento es <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </ng-template>

  </div>
  <div class="modal-footer clearfix">
    <div fxLayout="row">
      <button type="submit" class="btn btn-primary btn-lg" (click)="enviarEmail()"
        [disabled]="!forgotPassForm.valid || cargando">Enviar
      </button>
      <button type="button" class="btn btn-light btn-lg ml-2" (click)="hide.emit(true)">Cancelar
      </button>
    </div>
  </div>
</form>