import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotoneraEdicionComponent } from './botonera-edicion.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    BotoneraEdicionComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [BotoneraEdicionComponent]
})
export class BotoneraEdicionModule { }
