<div fxLayout="row wrap" fxLayoutAlign="start none" fxLayoutGap="32px" fxLayout.lt-md="column">
    <ng-template ngFor let-tarjeta [ngForOf]="tarjetas">
        <ng-template [ngIf]="tarjeta.permiso">
            <div    (click)="navigate(tarjeta.url)"
                    *ngxPermissionsOnly="tarjeta.permiso"
                    fxFlex="0 1 calc(33.3% - 32px)"
                    class="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-primary border-primary card widget-chart-hovered"
                    style="cursor: pointer">
                <div class="widget-chat-wrapper-outer">
                    <div class="widget-chart-content">
                        <div class="widget-title opacity-5 text-uppercase mb-1">
                            {{tarjeta.titulo}}
                        </div>
                        <ng-container *ngIf="tarjeta.subtitulo; else sinSubtitulo">
                            <div class="widget-subheading opacity-7 subheading-size">
                                {{tarjeta.subtitulo}}
                            </div>
                        </ng-container>
                        <ng-template #sinSubtitulo>
                        </ng-template>                       
                        <div class="widget-numbers mt-2 fsize-4 mb-0 w-100">
                            <div class="widget-chart-flex align-items-center">
                                <ng-container *ngIf="tarjeta.contador; else sinContador">
                                    {{tarjeta.contador | number}}
                                </ng-container>
                                <ng-template #sinContador>
                                    -
                                </ng-template>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template [ngIf]="!tarjeta.permiso">
            <div    (click)="navigate(tarjeta.url)"
                    fxFlex="0 1 calc(33.3% - 32px)"
                    class="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-primary border-primary card widget-chart-hovered"
                    style="cursor: pointer">
                <div class="widget-chat-wrapper-outer">
                    <div class="widget-chart-content">
                        <div class="widget-title opacity-5 text-uppercase mb-1">
                            {{tarjeta.titulo}}
                        </div>
                        <ng-container *ngIf="tarjeta.subtitulo; else sinSubtitulo">
                            <div class="widget-subheading opacity-7 subheading-size">
                                {{tarjeta.subtitulo}}
                            </div>
                        </ng-container>
                        <ng-template #sinSubtitulo>
                        </ng-template>                       
                        <div class="widget-numbers mt-2 fsize-4 mb-0 w-100">
                            <div class="widget-chart-flex align-items-center">
                                <ng-container *ngIf="tarjeta.contador; else sinContador">
                                    {{tarjeta.contador | number}}
                                </ng-container>
                                <ng-template #sinContador>
                                    -
                                </ng-template>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>