import { Injectable } from '@angular/core';
import { User } from '../auth/user';
import { ParametrosData } from '../Pages/Login/interfaces/parametros';


// key that is used to access the data in local storage
const STORAGE_USUARIO_KEY = 'datos_usuario_key';
// const STORAGE_ROL_KEY = 'rol_usuario_key';
const PERMISOS_NORMALIZADOS_KEY = 'permisos_normalizados_key';
const USER_KEY = 'user';
const TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refresh_token';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  // public usuario: User;

  constructor() {
  }

  public storeOnLocalStorage(user: User): void {
    // this.storage.set(STORAGE_USUARIO_KEY, user);
    if (user !== null) {
      user.password = '';
      localStorage.setItem(USER_KEY, JSON.stringify(user));
      localStorage.setItem(TOKEN_KEY, user.userData.access_token);
      localStorage.setItem(REFRESH_TOKEN_KEY, user.userData.refreshToken);
    }
  }

  public setToken(newToken: string): void {
    localStorage.setItem(TOKEN_KEY, newToken);
  }

  public setRefreshToken(newRefreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN_KEY, newRefreshToken);
  }

  public getUserFromStorage(): User {
    return JSON.parse(localStorage.getItem(USER_KEY));
    // return this.storage.get(STORAGE_USUARIO_KEY);
  }

  // Rol 1: administrador 2: paciente
  // public almacenarRolUsuario(idRol) {
  //   localStorage.setItem('rol', JSON.stringify(idRol));
  //   // this.storage.set(STORAGE_ROL_KEY, idRol);
  // }

  // public getRolFromStorage(): number {
  //   // return this.storage.get(STORAGE_ROL_KEY);
  //   return JSON.parse(localStorage.getItem('rol'));
  // }

  public cleanStorage(): void {
    // this.storage.remove(STORAGE_USUARIO_KEY);
    localStorage.clear();
  }

  public getTokenFromStorage(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public getRefreshTokenFromStorage(): string {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  public storeParametros(parametros: ParametrosData[]) {
    localStorage.setItem('parametros', JSON.stringify(parametros));
  }

  public getParametrosFromStorage(): ParametrosData {
    return JSON.parse(localStorage.getItem('parametros'));
  }

  // use local storage for messaging. Set message in local storage and clear it right away
  // This is a safe way how to communicate with other tabs while not leaving any traces
  //
  public message_broadcast(message) {
    localStorage.setItem('message', JSON.stringify(message));
    localStorage.removeItem('message');
  }

  // receive message
  //
  public message_receive(ev) {
    // console.log('Recibiendo evento: ' + ev.originalEvent.key + ' ' + ev.originalEvent.newValue);

    if (ev.originalEvent.key !== 'message') {
      return;
    } // ignore other keys
    const message = JSON.parse(ev.originalEvent.newValue);
    if (!message) {
      return;
    } // ignore empty msg or msg reset

    // here you act on messages.
    // you can send objects like { 'command': 'doit', 'data': 'abcd' }
    if (message.command === 'doit') {
      alert(message.data);
    }
  }

  public removeToken(user: User): void {
    localStorage.clear();
  }

  public storeSearch(search: any, searchFrom: string): void {
    this.clearStoreSearch();
    localStorage.setItem('search_from', searchFrom);
    localStorage.setItem('search', JSON.stringify(search.value));
  }

  public getStoreSearchFrom(): any {
    return localStorage.getItem('search_from');
  }

  public getStoreSearch(): any {
    return JSON.parse(localStorage.getItem('search'));
  }

  public clearStoreSearch(): void {
    localStorage.removeItem('search');
  }

  public saveItem(name: string, value: any): void {
    localStorage.setItem(name, JSON.stringify(value));
  }

  public getSavedItem(name: string): any {
    return JSON.parse(localStorage.getItem(name));
  }
}
