<div class="container-fluid imagen-fondo" fxLayout="column" fxLayoutAlign="space-between stretch" style="height: 40rem !important;">
    <div class="container-fluid">
        <div class="row mt-3 mb-5">
            <div class="col text-center" style="border-bottom: 1px solid gray;">
                <h6 class="welcome-usuario">¡Hola, {{usuario.userData.nombre}} {{usuario.userData.apellido}}!</h6>
            </div>
        </div>
    </div>
    <!-- <div fxLayout="column" fxLayoutAlign="end stretch"> 
        <div class="text-center">
            <h1>Sistema Web de Gestión Institucional</h1>
        </div>
    </div> -->
</div>
