import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarjetasDashboardComponent } from './tarjetas-dashboard.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [ TarjetasDashboardComponent ],
  imports: [ CommonModule, NgxPermissionsModule, FlexLayoutModule ],
  exports: [ TarjetasDashboardComponent ]
})
export class TarjetasDashboardModule { }
