<button class="close" mat-button (click)="onNoClick()">X</button>
<h2 mat-dialog-title>Mi perfil </h2>
<div mat-dialog-content>
  <ng-template [ngIf]="perfil">
    <div class="widget-content">
      <div fxLayout="column">
        <div fxLayout="row">
          <div fxFlex class="text-center" style="position: relative; align-items: stretch">
            <img width="90px" height="90px" src="./assets/images/avatars/avatar-man.png" alt="Foto de Perfil"
              class="rounded-circle" *ngIf="!imageToShowDialog">
            <img width="90px" height="90px" [src]="imageToShowDialog" alt="Foto de Perfil" class="rounded-circle"
              *ngIf="imageToShowDialog && !cargando" onerror="this.src='./assets/images/avatars/avatar-man.png'">
            <div class="spinner-border text-primary" role="status" *ngIf="cargando">
              <span class="sr-only">Loading...</span>
            </div>
            <label title="Cambiar Foto de Perfil" for="foto_perfil" class="d-inline mb-0 label-preppend-left"
              *ngIf="showInformacion">
              <i class="pe-7s-camera font-bold rounded-circle rounded-circle-bg-gray" style="font-size: 25px;"></i>
            </label>
          </div>
        </div>
      </div>
      <div fxLayout="column">
        <div fxLayout="row">
          <div fxFlex class="text-center">
            <a title="Ver Persona" class="text-dark" target="_blank"
              routerLink="/recursos-humanos/personas/{{perfil.adm_persona_id}}">
              <h5>{{perfil.nombre}} {{perfil.apellido}}</h5>
            </a>
          </div>
        </div>
        <div fxLayout="row" class="text-center">
          <div fxFlex class="text-center">
            <h6>{{perfil.email}}</h6>
          </div>
        </div>
        <!--        <div fxLayout="row">-->
        <!--          <div fxFlex class="text-center" *ngIf="perfil.direccion"> <i class="pe-7s-home" style="font-size: 20px;"></i>-->
        <!--            {{perfil.direccion}}</div>-->
        <!--          <div fxFlex class="text-center" *ngIf="perfil.telefono"> <i class="pe-7s-call" style="font-size: 20px;"></i>-->
        <!--            {{perfil.telefono}}</div>-->
        <!--        </div>-->
      </div>
    </div>
    <div fxLayout="row">
      <div fxFlex>
        <input hidden="true" type="file" id="foto_perfil" (change)="fileChangeEvent($event.target.files)">
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-between">
      <div fxLayout="column" fxFlex="70%">
        <mat-tab-group disableRipple animationDuration="0ms">
          <mat-tab label="Información General">
            <ng-template matTabContent>
              <div class="card-body">
                <app-user-info [userInfo]="perfil" (clickUpdateEvent)="getPerfil()"></app-user-info>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab label="Configuración">
            <ng-template matTabContent>
              <div class="card-body pb-0">
                <app-user-settings [data]="perfilForm.controls.email.value" (close)="dialogRef.close()">
                </app-user-settings>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </ng-template>
</div>